@import "utils/commonStyles/index.scss";

$padding-vertical: 30px;

.main-cnt {
  flex: 1;
  position: relative;
  overflow: hidden;

  .blob {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
  }

  .content {
    z-index: 1;
    padding: $padding-vertical 0;
    padding-left: $section-padding-horizontal;
    padding-right: 20px;
    position: relative;
    @include flex-row;
    height: calc(100% - (2 * $padding-vertical));

    .content--left {
      @include flex-col-h-cen;
      position: absolute;
      gap: 50px;
      height: 100%;

      .line {
        height: 100%;
        width: 1px;
        background: linear-gradient(
          180deg,
          #aa0eb2 0%,
          $color-10 33.57%,
          $color-30 65.86%,
          $color-60 100%
        );
      }
    }

    .content--mid {
      padding-left: 60px;
      display: inline-block;
      padding-top: 100px;
    }

    .content--right {
      padding-top: 100px;
      padding-left: 30px;
    }

    .name {
      font-size: toRem(60px);
      font-weight: bold;
      color: $color-primary-text;
    }
    .role {
      text-transform: uppercase;
      font-size: toRem(30px);
      font-weight: bold;
      color: $color-secondary-text;
    }
    .descrp {
      padding-top: 30px;
      max-width: 500px;
      text-align: justify;
      font-size: toRem(17px);

      a {
        color: $color-secondary-text;
      }
    }
    .btnsWrapper {
      margin-top: 50px;
      @include flex-row-v-cen;
      gap: 30;
    }
  }
}

@media only screen and (max-width: 600px) {
  .main-cnt {
    .content {
      .name {
        font-size: toRem(50px);
      }
      .role {
        font-size: toRem(20px);
      }
      .descrp {
        font-size: toRem(15px);
      }
    }
  }
}
