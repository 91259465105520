@import "utils/commonStyles/index.scss";

.main-cnt {
  @include flex-col;
  padding: $section-padding-vertical $section-padding-horizontal;

  .header-cnt {
    @include flex-row-v-cen;
    flex-wrap: wrap;
    gap: 15px;

    .filter {
      &.active {
        border-color: $color-action;
        background-color: $color-action;
      }

      // Reference: https://css-tricks.com/cool-hover-effects-using-background-properties/
      background: linear-gradient($color-action 0 0) var(--p, 0) / var(--p, 0) no-repeat;
      transition: 0.4s, background-position 0s;
      &:hover {
        --p: 100%;
      }

      display: inline-block;
      padding: 6px 5px;
      background-color: $color-secondary;
      color: #999;
      border-width: 1px 1px 2px 1px;
      border-style: solid;
      border-color: #222;
      border-radius: 3px;
      text-transform: uppercase;
      font-family: MontSerrat, Tahoma, Arial, sans-serif;
      font-weight: 700;
      font-size: 0.75rem;
      box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
      opacity: 1;
      cursor: pointer;
    }
  }

  .content-cnt {
    padding: 40px 0;
    @include flex-row-v-cen;
    flex-wrap: wrap;
    gap: 50px;

    > * {
      width: calc(50% - 25px);
    }

    animation: fadein 0.8s ease-out forwards;
  }
}

@media only screen and (max-width: 800px) {
  .main-cnt {
    .content-cnt {
      > * {
        width: calc(100%);
      }
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
