@import "utils/commonStyles/index.scss";

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  @extend %scrollbar;
  overscroll-behavior: none;
  overflow: overlay;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  overscroll-behavior: none;
  width: inherit;
  height: inherit;
}

#root {
  width: inherit;
  height: inherit;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.app {
  background: $color-primary;
  color: $color-primary-text;
  // font-family: "Roboto", sans-serif;
  font-family: "Spline Sans", sans-serif;
  position: relative;
  @extend %scrollbar;
  overflow: overlay;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
