@import "utils/commonStyles/index.scss";

$gap-between-menu-items: 50px;

.header {
  @include flex-row-v-cen;
  padding: 15px;
  padding-left: 6%;
  padding-right: 6%;
  position: sticky;
  top: 0;
  z-index: 999;
  gap: $gap-between-menu-items;
  background-color: $color-primary;

  &.header-shadow {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px;
    background-color: $color-primary;
  }

  &.no-shadow {
    box-shadow: none;
    background-color: none;
  }

  .header--left {
    svg {
      width: 70px;
      path {
        fill: $color-white-text !important;
      }
    }
  }

  .header--mid-web {
    @include flex-row-v-cen;
    font-size: toRem(16px);
    gap: $gap-between-menu-items;

    a {
      padding: 4px 10px;
      font-weight: bold;
      color: $color-primary-text;
      text-decoration: none;
      border-bottom: 1px solid transparent;

      &.active {
        border-bottom: 1px solid $color-action;
      }
    }
  }

  .header--right-web {
    @include flex-row-v-cen;
    justify-content: flex-end;
    flex: 1;
  }

  .getInTouchBtn {
    background-color: $color-secondary;
    &:hover {
      background-color: $color-action;
    }
  }

  .header--right-mobile {
    display: none;

    .hamburgerIcon {
      color: $color-primary-text;
      font-size: toRem(30px);
    }

    .header--right-mobile-btn {
      background-color: inherit;
    }

    .header--right-mobile-menu-items {
      @include flex-col;
      justify-content: flex-end;
      align-items: flex-end;
      text-align: right;
      right: 15px;

      a {
        font-weight: bold;
        color: $color-primary-text;
        text-decoration: none;
        padding: 2px 0;
        margin: 10px 0;
        background-color: $color-primary;

        &.active {
          border-bottom: 1px solid $color-action;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .header {
    padding: 20px 15px;
    justify-content: space-between;
    gap: 20px;

    .header--mid-web {
      display: none;
    }

    .header--right-mobile {
      display: block;
    }
  }
}
