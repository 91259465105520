@import "utils/commonStyles/index.scss";

.main-header {
  color: $color-primary-text;
  text-align: center;
  padding-bottom: 50px;

  .main-header__name {
    font-size: 25px;
  }
}
