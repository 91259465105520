/**
Color Palette
*******************************************************
Step 1: Find out brand color from this website: https://colorhunt.co/
Step 2: Generate remaining colors from here: https://mycolor.space/
Step 3: Choose three colours (color-60, color-30, & color-10) excluding white, black and grey
*/
$color-60: #092635;
$color-30: #00505f;
$color-30-6: hsla(189, 100%, 19%, 0.6);
$color-10: #007d79;

/*
*
*
*
*
*******************************************************
Only access colors through these “proxy”-variables
*******************************************************
*/
$color-primary: $color-60;
$color-secondary: $color-30;
$color-action: $color-10;
$color-action-hover: $color-30;
$color-primary-text: rgba(238, 238, 238, 0.8);
$color-secondary-text: rgba(238, 238, 238, 0.5);
$color-white-text: #ffffff;
$color-black-text: #000;
$color-scrollbar-thumb: rgba(255, 255, 255, 0.2);
$color-scrollbar-bg: transparent;
$color-scrollbar-bg-hover: #555;
$color-white: #ffffff;
