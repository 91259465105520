@import "utils/commonStyles/index.scss";

.main-cnt {
  padding: $section-padding-vertical $section-padding-horizontal;
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;

  .header {
    @include flex-row-v-cen;
    justify-content: flex-end;
    padding: 0 75px;
    padding-bottom: 20px;
    gap: 30px;
    .skill-category {
      padding: 6px 12px;
      color: $color-primary-text;
      border: 1px solid $color-secondary-text;
      border-radius: 10px;
      &.active {
        border-color: $color-action;
      }
    }
  }

  .skills-cnt {
    @include flex-row;
    justify-content: space-between;
    flex-wrap: wrap;

    &:last-child {
      justify-content: space-evenly;
    }

    .skill {
      @include flex-col;
      align-items: center;
      justify-content: space-between;
      min-width: 200px;
      padding: 30px 0;

      svg {
        cursor: pointer;
        font-size: toRem(50px);
        height: 50px;
        width: 50px;
        animation: tada 0.3s;

        animation-duration: 1s;
        -webkit-animation-duration: 1s;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
        &:hover {
          -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
        }
      }

      .skill-name {
        padding-top: 12px;
        font-size: toRem(16px);
      }
    }
  }
}

.hoverIllustration {
  $offwhite: rgba(238, 238, 238, 0.5);
  position: absolute;
  bottom: 20px;
  right: 30px;
  @include flex-col-h-cen;
  svg {
    fill: $offwhite;
    height: toRem(50px);
  }
  color: $offwhite;
  div {
    padding-left: 10px;
  }
}

.skillDetails {
  color: $color-black-text;
  display: grid;
  gap: 10px;
  padding: 20px;
  .detailsRow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .detailsKey {
    }

    .detailsValue {
      text-align: right;
    }
  }
}

@media only screen and (max-width: 600px) {
  .main-cnt {
    padding: $section-padding;
    padding-top: 0px;
    .header {
      padding: 0;
      justify-content: flex-start;
      padding-left: 25px;
    }

    .skills-cnt {
      justify-content: center;
      padding: 0;

      .skill {
        min-width: 150px;
      }
    }
  }

  .hoverIllustration {
    position: relative;
    align-self: flex-end;
  }
}
