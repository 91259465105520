@import "utils/commonStyles/index.scss";

.project-box-cnt {
  @include flex-col;
  border: 1px solid $color-secondary-text;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  .header {
    background-color: $color-white;
    @include flex-row-v-cen;
    justify-content: center;
    padding: 6px 0;
    opacity: 0.8;
    position: relative;

    .header--left {
      @include flex-row-v-cen;
      opacity: 0;
    }

    .header--right {
      position: absolute;
      right: 15px;
      @include flex-row-v-cen;
      gap: 15px;
      svg {
        font-size: toRem(25px);
        color: rgba(0, 0, 0, 0.6);
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        &:hover {
          scale: 1.2;
        }
      }
    }
  }

  .content-img-cnt {
    height: 320px;
    position: relative;
    display: flex;

    .overview-img,
    .overview-video {
      width: 100%; // IMPORTANT: To fit the parent container
      height: 100%; // IMPORTANT: To fit the parent container
      object-fit: contain; // IMPORTANT: To maintain the aspect ratio when height & width is adjusted to fit the container.
    }

    .overview-img {
      opacity: 0.5;
      transition: 0.3s all ease-in-out;

      &:hover {
        opacity: 0.3;
      }
    }

    .overview-video {
      border: none !important;
      outline: none !important;
    }

    &:hover {
      .skills-outer-cnt {
        opacity: 1;
      }
    }
    .skills-outer-cnt {
      transition: 0.3s all ease-in-out;
      opacity: 0;
      z-index: -10;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      @include flex-col-b-cen;
      .skills-cnt {
        @include flex-row-b-cen;
        flex-wrap: wrap;
        gap: 15px 20px;
        max-width: 400px;
        .skill {
          color: $color-secondary-text;
          @include flex-row-v-cen;
          gap: 5px;
          svg {
            height: 25px;
          }
        }
      }

      .more {
        color: $color-secondary-text;
        padding-top: 25px;
        letter-spacing: 2px;
      }
    }
  }

  .banner-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -5px;
    left: -5px;
  }

  .banner {
    width: 200px;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 15px;
    background-color: $color-secondary;
    opacity: 0.6;
    color: #fff;
    text-align: center;
    transform: rotate(-45deg) translate(-28%, -35%);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    letter-spacing: 1px;
  }
}

@media only screen and (max-width: 600px) {
}
