.button {
  height: 100%;
  box-sizing: border-box;
}

.button__btn {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-transform: capitalize;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.button__btn--with-shadow {
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
