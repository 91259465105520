@import "utils/commonStyles/colors.scss";

%scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $color-scrollbar-bg;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-scrollbar-thumb;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-scrollbar-bg-hover;
  }
}
