@import "utils/commonStyles/index.scss";

.main-cnt {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);

  .footer-content {
    font-size: 13px;
    text-align: center;
    color: $color-white-text;
  }
}

@media only screen and (max-width: 600px) {
  .main-cnt {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .footer-content {
    }
  }
}
