@import "utils/commonStyles/index.scss";

.main-cnt {
  @include section-padding();
  padding-left: 12%;
  padding-right: 12%;
  position: relative;

  .experience-cnt {
    display: grid;
    grid-gap: 100px;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;

    // All childs
    .experience-card:nth-child(n) {
      z-index: 99;
      min-height: 500px;
    }

    // To keep the even childs in the 2nd column
    .experience-card:nth-child(2n) {
      grid-column: span 1;
    }

    $n: 10;
    @for $i from 1 through $n {
      .experience-card:nth-child(#{$i}) {
        grid-row: ((3 * $i) - 2) / span 4;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .main-cnt {
    .experience-cnt {
      grid-gap: 120px;
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: 1fr;

      .experience-card:nth-child(n) {
        grid-column: 1;
        grid-row: unset;
      }

      .experience-card:last-child {
        margin-bottom: 50px;
      }
    }
  }
}
